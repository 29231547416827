<template lang="pug">
.brand-wrapper
  .container-fluid
    .row(v-if="loading" style="height: calc(100vh - 8rem)")
      loading-logo
    template(v-else)
      affiliate-summary(:data="affiliate" :payoutItems="payoutItems")
        template(slot="title")
          om-heading(h1) {{ $t('ambassador') }}
      .row.mt-5
        .col
          .affiliate-use-link.mb-3 {{ $t('affiliate.useThisLink') }}
          div(v-if="!affiliateLink") {{ $t('affiliate.noLinkAvailable') }}
          template(v-else)
            .d-flex-1.align-items-center.mb-3
              input.form-control.form-control-md.text-center.bg-white.mr-2(
                disabled="disabled"
                :value="affiliateLink"
                style="width: 31.25rem"
              )
              om-button(
                primary
                ghost
                v-clipboard:copy="affiliateLink"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ) {{ $t('affiliate.copyUrl') }}
      .row.mt-6
        .d-flex-1.flex-wrap
          .col-12.col-lg-6.mb-3
            .card.affiliate-informations-box
              .affiliate-informations-box-header
                .affiliate-informations-box-header-title {{ $t('affiliate.payoutDetails') }}

              .row.affiliate-informations-box-row.affiliate-informations-box-row-bordered.d-flex
                .col-6.affiliate-informations-box-col {{ $t('affiliate.commissionRate') }}
                .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.commissions[0].total }}
                .col-6.affiliate-informations-box-col {{ $t('affiliate.minimumPayout') }}
                .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.main.minimum_payout }}

              .row.affiliate-informations-box-row.affiliate-informations-box-row-bordered.d-flex
                .col-6.affiliate-informations-box-col {{ $t('affiliate.paid') }}
                .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.main.total_all_payout }}
                .col-6.affiliate-informations-box-col {{ $t('affiliate.awaitingPayout') }}
                .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.main.total_actual_do_payout }}

              .row.affiliate-informations-box-row.d-flex.align-items-center
                .col-6.affiliate-informations-box-col.large {{ $t('affiliate.payoutAmount') }}
                .col-6.affiliate-informations-box-col.large
                  .d-flex.align-items-center
                    div {{ affiliate.main.total_actual_can_payout }}
                    om-button.ml-auto(
                      primary
                      :to="{ name: 'ambassador_dashboard_payout_request' }"
                    ) {{ $t('ambassadorPayoutRequestPage.startButton') }}
              .row
                .col-7
                .col-5
                  .mt-0.brand-under-heading-text.text-right {{ $t('ambassadorPayoutRequestPage.startButtonInfo') }}
          .col-12.col-lg-6.mb-3
            .card.affiliate-informations-box.affiliate-informations-box-invoicing.mb-6.pl-5
              .affiliate-informations-box-header
                .affiliate-informations-box-header-title {{ $t('ourInvoiceDetails.title') }}

              .affiliate-informations-box-row.d-flex.pb-0
                .col-3.affiliate-informations-box-col {{ $t('affiliate.name') }}
                .col-9.affiliate-informations-box-col.font-weight-bold {{ $t('ourInvoiceDetails.name') }}

              .affiliate-informations-box-row.d-flex.pb-0
                .col-3.affiliate-informations-box-col {{ $t('address') }}
                .col-9.affiliate-informations-box-col.font-weight-bold {{ $t('ourInvoiceDetails.address') }}

              .affiliate-informations-box-row.d-flex.pb-0
                .col-3.affiliate-informations-box-col {{ $t('euVatNumber') }}
                .col-9.affiliate-informations-box-col.font-weight-bold {{ $t('ourInvoiceDetails.vat') }}

      .row.flex-column.mt-6(v-if="$i18n.locale == 'en' && !partnerTypeService.isPartner()")
        .col
          .affiliate-page-bottom-title.font-weight-bold.mb-3 {{ $t('affiliate.learnMoreAgencyTitle') }}
          .affiliate-page-bottom-desc(v-html="$t('affiliate.learnMoreAgencySubtitle')")

  om-modal(name="payout-success-modal" :width="520" clickToClose)
    template(slot="modal-header")
      .row.m-0.p-0
        .font-weight-bold.font-size-1--25 {{ $t('ambassadorPayoutRequestPage.successModal.title') }}
      .brand-modal-action-icon
        span.cursor-pointer(@click="$modal.hide('payout-success-modal')")
          close-icon(:width="14" :height="14" :color="'#C2C2C2'")
    template(slot="modal-body")
      p
        span {{ $t('notifications.payoutSuccess') }}
      p
        span {{ $t('ambassadorPayoutRequestPage.successModal.message') }}
    template(slot="modal-footer")
      .d-flex.justify-content-center.mt-2
        om-button(primary @click="$modal.hide('payout-success-modal')") {{ $t('ambassadorPayoutRequestPage.successModal.actionButton') }}
</template>

<script>
  import moment from 'moment';
  import { mapState, mapMutations, mapActions } from 'vuex';

  import AffiliateSummary from '@/components/Affiliate/AffiliateSummary';

  import { stripTags } from '@/util';
  import affiliateDataMixin from '@/mixins/affiliate-data';

  import UPDATE_AFFILIATE_PARTNER_INFO from '@/graphql/UpdateAffiliatePartnerInfo.gql';

  export default {
    components: { AffiliateSummary },
    mixins: [affiliateDataMixin],

    data() {
      return {
        loading: true,
        showSuccessPayoutModal: true,
      };
    },

    computed: {
      ...mapState(['account', 'partnerTypeService']),
      partnerInfo() {
        return this.account.settings.affiliate.partnerInfo;
      },
      partnerId() {
        return this.affiliate.partner_data.code;
      },
      affiliateLink() {
        const domain = this.$i18n.locale === 'hu' ? 'www.optimonk.hu' : 'www.optimonk.com';
        return this.partnerId ? `https://${domain}?p_id=${this.partnerId}` : null;
      },
      hasValidSuccesfulPayoutAction() {
        if (!this.$route.query?.successfulPayoutRequest) return false;
        if (!this.$route.query?.ts) return false;

        const unixTs = Math.round(this.$route.query.ts / 1000);
        return moment.unix(unixTs).isAfter(moment().subtract(5, 'seconds'));
      },
    },

    async created() {
      // eslint-disable-next-line no-control-regex
      if (/[^\x00-\x7F]/g.test(this.partnerInfo.slug)) {
        console.info('Non-ASCII slug need to reassign...');
        await this.$apollo.mutate({
          mutation: UPDATE_AFFILIATE_PARTNER_INFO,
          variables: {
            data: {
              ...this.settings,
              migrateSlug: true,
            },
          },
        });
        await this.fetchAccount();
      }
    },

    async mounted() {
      if (this.hasValidSuccesfulPayoutAction) {
        this.$modal.show('payout-success-modal');
      }

      await this.setAffiliateData();
      this.setPayoutItems();
      this.loading = false;
    },

    methods: {
      stripTags,
      ...mapActions(['fetchAccount']),
      ...mapMutations(['showAdminLoader']),
      onCopy() {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.urlCopied'),
        });
      },

      onError() {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.clipboardCopyError'),
        });
      },
    },
  };
</script>

<style lang="sass">
  .agency-partner-program-box
    width: 100%
    justify-content: space-between
    align-items: center
    background: url('~@/assets/admin/img/agency-affiliate-banner.png')
    background-repeat: no-repeat
    background-size: cover
    padding: 1.75rem
    border-radius: 5px
    h3
      font-weight: 300
      margin-bottom: 4px
      font-size: 1.25rem
      font-weight: bold
    h4
      font-size: 1.25rem
      margin-bottom: 0
      font-weight: normal
    .brand-btn
      font-weight: bold
      font-size: 1.125rem
      padding: .625rem 2.0625rem
      width: 104px
  @media screen and (max-width: 1199px)
    .agency-partner-program-box
      // background: white url('~@/assets/admin/img/dots.jpg') no-repeat 15px 20%
      backgroud-size: 100%
      h4
        margin-bottom: 10px
</style>
